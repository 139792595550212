// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lander-example-tsx": () => import("../src/pages/lander-example.tsx" /* webpackChunkName: "component---src-pages-lander-example-tsx" */),
  "component---src-pages-launch-scores-v-2-tsx": () => import("../src/pages/launch-scores-v2.tsx" /* webpackChunkName: "component---src-pages-launch-scores-v-2-tsx" */),
  "component---src-pages-launch-scores-tsx": () => import("../src/pages/launch-scores.tsx" /* webpackChunkName: "component---src-pages-launch-scores-tsx" */),
  "component---src-pages-new-heights-v-2-tsx": () => import("../src/pages/new-heights-v2.tsx" /* webpackChunkName: "component---src-pages-new-heights-v-2-tsx" */),
  "component---src-pages-new-heights-tsx": () => import("../src/pages/new-heights.tsx" /* webpackChunkName: "component---src-pages-new-heights-tsx" */)
}

